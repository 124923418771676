import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Interface
import { CertificationsProps } from 'components/flex/Certifications/CertificationsShell'
import Plaatjie from '@ubo/plaatjie'

const Section = styled.section`
  background-color: ${({ theme }) => theme.color.primary};
  margin-bottom: 10rem;
`

const Content = styled(ParseContent)`
  color: ${({ theme }) => theme.color.light};
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    color: ${({ theme }) => theme.color.light};

    @media (min-width: 1200px) {
      font-size: ${({ theme }) => theme.font.size.ultra};
      line-height: ${({ theme }) => theme.font.size.ultra};
    }
  }
`

const Certs = styled.div`
  background-color: ${({ theme }) => theme.color.light};
  color: ${({ theme }) => theme.color.primary};
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  margin-bottom: -9rem;
`

const CertLink = styled.a`
  color: ${({ theme }) => theme.color.primary};

  &:hover {
    text-decoration: underline;
  }
`

const CertImage = styled(Plaatjie)`
  height: 100px;
  width: auto;
  object-fit: cover;
`

const Certifications: React.FC<CertificationsProps> = ({ fields }) => {
  return (
    <Section>
      <div className="container py-5">
        <div className="row">
          <div className="col-lg-6">
            <Content content={fields.description} />
          </div>
          <div className="col-lg-6">
            <Content content={fields.descriptiontwo} />
          </div>
        </div>
        <Certs className="row justify-content-center mt-5 py-5">
          {fields.certifications?.map((certification, index) => (
            <div
              className="col d-flex flex-column justify-content-center align-items-center"
              key={index}
            >
              <CertImage image={certification?.icon} alt="" className="mb-3" />
              <CertLink
                className="text-center"
                style={{ minHeight: 48 }}
                href={certification?.link?.url}
                target="_blank"
              >
                {certification?.link?.title}
              </CertLink>
            </div>
          ))}
        </Certs>
      </div>
    </Section>
  )
}

export default Certifications
